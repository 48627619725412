import Actions from '../actions';
import {storage} from 'services';

const initialState = {
  language: storage.get('language'),
  regions: [],
  marquee: {},
  notification: 0
};

const systemReducer = (state = initialState, action) => {

  switch (action.type) {
    case Actions.CHANGE_LANG.SUCCESS:
      storage.set("language", action.payload);
      return {
        ...state,
        language: action.payload
      };
    case Actions.REGIONS.SUCCESS:
      return {
        ...state,
        regions: action.payload
      };
    case Actions.MARQUEE.SUCCESS:
      return {
        ...state,
        marquee: action.payload
      };
      case Actions.NOTIFICATION.SUCCESS:
        return {
          ...state,
          notification: action.payload
        };
    default:
      return state
  }
};

export default systemReducer;