import Actions from '../actions';
import { storage } from 'services';
import get from "lodash.get";

const initialState = {
  isFetched: true,
  isAuthenticated: !!storage.get('token'),
  data: JSON.parse(storage.get('user')),
  token: storage.get('token')
};

const Auth = (state=initialState, action) => {
  switch(action.type){
    case Actions.LOGIN.REQUEST:
      return {
        ...state,
        isFetched: false,
        isAuthenticated: false,
        token: '',
        data: {}
      };
    case Actions.LOGIN.SUCCESS:
      storage.set("token", get(action.payload, "token"));
      storage.set('user', JSON.stringify(action.payload));
      return {
        isFetched: true,
        isAuthenticated: true,
        token: action.payload.token,
        data: action.payload
      };
    case Actions.LOGOUT.SUCCESS:
    case Actions.LOGIN.FAILURE:
    case Actions.LOGOUT.FAILURE:
      storage.remove('token');
      storage.remove('user');
      return {
        ...state,
        isFetched: true,
        isAuthenticated: false,
        token: '',
        data: {}
      };
    default:
      return state
  }
};

export default Auth;