import React from 'react';
import {Link} from 'react-router-dom';

const NotFound = () => {
  // const {auth} = useSelector(state => state);
  // const navigate = useNavigate()

/*  useEffect(() => {
    if (!auth.isAuthenticated) {
      return navigate("/")
    }
  })*/

  return (
    <div className={"p-5"}>
      <div className={"text-center"}>
        <h1 className={"mt-3 mb-5 font-size-18 text-primary2"}>Страница не найдена :(</h1>
        <Link to={"/"} className={"btn btn-primary2 text-white focus-none"}>
          <i className={"fal fa-angle-left me-2"}/> {" "} Главный
        </Link>
      </div>
    </div>
  );


}

export default NotFound;