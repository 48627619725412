import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from "react-i18next";
import { YMaps } from "@pbe/react-yandex-maps";
import { i18n } from "services";
import App from "./App";
import { api } from 'services';
import configureStore from './redux/store';
import './assets/style/custom.scss';
import { ProSidebarProvider } from 'react-pro-sidebar';

const store = configureStore();

store.subscribe(() => {
	api.subscribe(store);
});

ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={"Loading..."}>
        <I18nextProvider i18n={i18n}>
          <YMaps>
          <ProSidebarProvider>
            <App />
          </ProSidebarProvider>
          </YMaps>
        </I18nextProvider>
      </Suspense>
    </Provider>,
    document.getElementById('root')
);