const {
  REACT_APP_API_ROOT,
  REACT_APP_FILE_ROOT,
  REACT_APP_FILE_ROOT_2,
  REACT_APP_FILE_ROOT_3,
  REACT_APP_FILE_ROOT_4,
  REACT_APP_FILE_ROOT_5,
} = process.env;

const config = {
  API_ROOT: REACT_APP_API_ROOT,
  FILE_ROOT: REACT_APP_FILE_ROOT,
  FILE_ROOT2: REACT_APP_FILE_ROOT_2,
  FILE_ROOT3: REACT_APP_FILE_ROOT_3,
  FILE_ROOT4: REACT_APP_FILE_ROOT_4,
  FILE_ROOT5: REACT_APP_FILE_ROOT_5,
};
export default config;