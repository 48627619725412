import React, {useEffect} from 'react';
import Routes from './routes';
import 'suneditor/dist/css/suneditor.min.css';
import 'react-toastify/dist/ReactToastify.min.css'; 
import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import {ToastContainer} from 'react-toastify';
import {redirect} from "react-router-dom";
import {storage} from 'services';
import Actions from 'redux/actions';
import dayjs from 'dayjs';

// // Import Swiper styles
import "swiper/swiper.css";
import 'swiper/modules/navigation/navigation.scss';
require('dayjs/locale/ru');
require('assets/script/uz-latn');

const App = ({i18n} ) => {
  const dispatch = useDispatch();
  const {auth, system: {language}} = useSelector(state => state);
  useEffect(()=> {
    if (parseInt(auth?.data?.user?.role?.[0]?.id) === 3) {
      dispatch(Actions.NOTIFICATION.request());
    }
  },[auth?.data?.user?.role, dispatch])
  useEffect(() => {
    if (!auth?.isAuthenticated) {
      redirect("/login/email")
    }
  }, [auth?.isAuthenticated])
  
  useEffect(() => {
    if (!storage.get('language')) {
      dispatch(Actions.CHANGE_LANG.success('uz'));
      dayjs.locale('uz');
    }
  }, [dispatch, language]);

  useEffect(() => {
    i18n.changeLanguage(language);
    dayjs.locale(language);
  }, [i18n, language]);

  return (
    <div className="body-main">
      <Routes/>
      <ToastContainer autoClose={3000}/>
    </div>
  );
};


export default withTranslation()(App);