import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SimpleBarReact from "simplebar-react/dist/simplebar-react";
import "simplebar/src/simplebar.css";
// images
import statistics1 from "../../../assets/images/build.png"
import statistics2 from "../../../assets/images/ruler.png"
import statistics3 from "../../../assets/images/setting.png"
import statistics4 from "../../../assets/images/flag.png"
import statistics5 from "../../../assets/images/house.png"
const headerData = [
  {
    title: "Barcha obyektlar",
    image: statistics1,
    statistics: "7143",
    percentage: "2,55",
    fon: "linear-gradient(270deg, #637CFF 0%, #71A3FE 100%)",
    color: "#336FEE"
  },
  {
    title: "Loyiha ishlari uchun tender e’lon qilingan",
    image: statistics2,
    statistics: "230",
    percentage: "4,25",
    fon: "linear-gradient(270deg, #FF6F75 0%, #FF748F 100%), #FFFFFF",
    color: "#FF6F77"
  },
  {
    title: "Qurilish ishlari uchun tender e’lon qilingan",
    image: statistics3,
    statistics: "340",
    percentage: "6,25",
    fon: "linear-gradient(270deg, #FF8F64 0%, #FFAC64 100%)",
    color: "#FF9264"
  },
  {
    title: "Qurilish jarayonida",
    image: statistics4,
    statistics: "11243",
    percentage: "81,75",
    fon: "linear-gradient(270deg, #A65BFB 0%, #BC5DFF 100%)",
    color: "#A65BFB"
  },
  {
    title: "Foydalanishga topshirilgan",
    image: statistics5,
    statistics: "321",
    percentage: "10.04",
    fon: "linear-gradient(270deg, #4FC760 0%, #62DC73 100%)",
    color: "#52C962"
  }
]

const Statistics = () => {
  const [swiper, setSwiper] = useState();

  return (
  <div className="container">
    <div className="d-flex justify-content-between text-align-center pt-56 pb-24">
    <div className="fs-44 lh-44 fw-600 text-darkblue">Statistika</div>
    <div>
      <button className="btn bg-white text-primary4" onClick={()=> swiper?.slideNext ? swiper?.slidePrev() : null}>
        <i className="far fa-chevron-left"></i>
      </button>
      <button className="btn bg-white ms-3 text-primary4" onClick={()=> swiper?.slideNext ? swiper?.slideNext() : null}>
        <i className="far fa-chevron-right"></i>
      </button>
    </div>
  </div>
  
  <Swiper
      slidesPerView={"auto"}
      spaceBetween={20}
      onSwiper={(evt) => setSwiper(evt)}
      className="mb-20"
    >
      {
        headerData.map((value, key)=>(
          <SwiperSlide key={key}
            style={{width: "257px"}}
          >
            <div className="rounded-24 p-4" style={{height: "257px", background: value.fon}}>
              <span className="p-3 d-inline-block rounded-8 mb-20" style={{backgroundColor: "rgba(255, 255, 255, 0.7)"}}>
                <img src={value?.image} alt="" className="d-block"/>
              </span>
              <div className="fw-500 fs-16 lh-24" style={{color: "rgba(255, 255, 255, 0.8)", height: "50px"}}>{value?.title}</div>
              <div className="fw-500 fs-28 lh-34 text-white mb-20">{value?.statistics}</div>
              <div className="rounded-10 fw-500 fs-18 lh-22 d-inline-block px-3 py-1" style={{backgroundColor: "rgba(255, 255, 255, 0.7)", color: value?.color}}>{value?.percentage}</div>
            </div>
          </SwiperSlide>
        ))
      }
    </Swiper>


    <div className="row my-4">
      <div className="col-4">
        <div className="bg-white rounded-24 pb-3">
          <div className="fw-500 fs-18 lh-22 d-flex justify-content-between p-4 border-3 border-bottom border-frozen2">
            <div className="text-darkblue">Hududlar bo’yicha</div>
            <div className="text-muted">31789</div>
          </div>
          <SimpleBarReact style={{ maxHeight: 492, padding: "0px 20px 0px 24px", marginRight:"8px"}}>
            <ul>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between text-darkblue7 py-2">
                <div className="fs-14 lh-17">Xorazm viloyati</div>
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-14 lh-17 fw-600">880</div>
                  <div className="bg-success8 text-success9 fs-14 lh-17 fw-500 px-2 py-1 rounded-pill">99%</div>
                </div>
              </li>
            </ul>
          </SimpleBarReact>
        </div>
      </div>
      <div className="col-4">
        <div className="bg-white rounded-24 pb-3">
          <div className="fw-500 fs-18 lh-22 d-flex justify-content-between p-4 border-3 border-bottom border-frozen2">
            <div className="text-darkblue">Buyurtmachilar bo’yicha</div>
            <div className="text-muted">31789</div>
          </div>
          <SimpleBarReact style={{ maxHeight: 492, padding: "0px 20px 0px 24px", marginRight:"8px"}}>
            <ul className="py-2">
              <li className="d-flex justify-content-between bg-muted2 rounded-8 py-1 px-2 text-darkblue7 my-2">
                <div className="fs-13">Xorazm viloyati hokimligining "YAGONA BUYURTMACHI XIZMATI" i</div>
                <div className="fs-14 lh-17 fw-600">880</div>
              </li>
              <li className="d-flex justify-content-between bg-muted2 rounded-8 py-1 px-2 text-darkblue7 my-2">
                <div className="fs-13">Xorazm viloyati hokimligining "YAGONA BUYURTMACHI XIZMATI" i</div>
                <div className="fs-14 lh-17 fw-600">880</div>
              </li>
              <li className="d-flex justify-content-between bg-muted2 rounded-8 py-1 px-2 text-darkblue7 my-2">
                <div className="fs-13">Xorazm viloyati hokimligining "YAGONA BUYURTMACHI XIZMATI" i</div>
                <div className="fs-14 lh-17 fw-600">880</div>
              </li>
              <li className="d-flex justify-content-between bg-muted2 rounded-8 py-1 px-2 text-darkblue7 my-2">
                <div className="fs-13">Xorazm viloyati hokimligining "YAGONA BUYURTMACHI XIZMATI" i</div>
                <div className="fs-14 lh-17 fw-600">880</div>
              </li>
              <li className="d-flex justify-content-between bg-muted2 rounded-8 py-1 px-2 text-darkblue7 my-2">
                <div className="fs-13">Xorazm viloyati hokimligining "YAGONA BUYURTMACHI XIZMATI" i</div>
                <div className="fs-14 lh-17 fw-600">880</div>
              </li>
              <li className="d-flex justify-content-between bg-muted2 rounded-8 py-1 px-2 text-darkblue7 my-2">
                <div className="fs-13">Xorazm viloyati hokimligining "YAGONA BUYURTMACHI XIZMATI" i</div>
                <div className="fs-14 lh-17 fw-600">880</div>
              </li>
              <li className="d-flex justify-content-between bg-muted2 rounded-8 py-1 px-2 text-darkblue7 my-2">
                <div className="fs-13">Xorazm viloyati hokimligining "YAGONA BUYURTMACHI XIZMATI" i</div>
                <div className="fs-14 lh-17 fw-600">880</div>
              </li>
              <li className="d-flex justify-content-between bg-muted2 rounded-8 py-1 px-2 text-darkblue7 my-2">
                <div className="fs-13">Xorazm viloyati hokimligining "YAGONA BUYURTMACHI XIZMATI" i</div>
                <div className="fs-14 lh-17 fw-600">880</div>
              </li>
              <li className="d-flex justify-content-between bg-muted2 rounded-8 py-1 px-2 text-darkblue7 my-2">
                <div className="fs-13">Xorazm viloyati hokimligining "YAGONA BUYURTMACHI XIZMATI" i</div>
                <div className="fs-14 lh-17 fw-600">880</div>
              </li>
            </ul>
          </SimpleBarReact>
        </div>
      </div>
      <div className="col-4">
      <div className="bg-white rounded-24 pb-3">
        <div className="fw-500 fs-18 lh-22 d-flex justify-content-between p-4 border-3 border-bottom border-frozen2">
          <div className="text-darkblue">Sohalar bo’yicha</div>
          <div className="text-muted">31789</div>
        </div>
        <SimpleBarReact style={{ maxHeight: 492, padding: "0px 14px 0px 24px", marginRight:"8px"}}>
        <ul className="w-100">
        {[...Array(50)].map((x, i) => (
          <li className="text-darkblue7 py-2 my-1 d-flex justify-content-between">
            <div className=" fs-14 lh-17 truncate-1">Умумтаълим мактаблари Умумтаълим мактаблари</div>
            <div className=" fs-14 lh-17 fw-600  mx-2">31789</div>
          </li>
        ))}
        </ul>
      </SimpleBarReact>
      </div>
      </div>
    </div>
  </div>
  )
}

export default Statistics;