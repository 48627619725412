import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from "react-redux";
import NotFound from './pages/404';
import get from 'lodash.get';
import Statistics from 'pages/public/Statistics';

//public pages
const MainLayout = lazy(() => import("layout/MainLayout"))
const Home = lazy(() => import("./pages/public/Home"))
const Objects = lazy(() => import("pages/public/ObjectsPage/Objects"))
const ObjectsItem = lazy(() => import("pages/public/ObjectsPage/ObjectItem"))
const News = lazy(() => import("pages/public/News/News"))
const SelectedNews = lazy(() => import("pages/public/News/SelectedNews"))
const Portal = lazy(() => import("pages/public/Portal"))
const Contact = lazy(() => import("pages/public/Contact"))
const Login = lazy(() => import("./pages/public/Login"))

//privatePages
const DashboardLayout = lazy(() => import("layout/DashboardLayout"));
const InnerLayout = lazy(() => import('layout/InnerLayout'));
const Dashboard = lazy(() => import("./pages/Main"));
// const CreateObject = lazy(() => import("./pages/CreateObject"));
const UserShow = lazy(() => import("./pages/UserShow"));
const EditObject = lazy(() => import("./pages/EditObject"));
const EditObject2 = lazy(() => import("./pages/EditObject2"));
const PrivateObjects = lazy(() => import("./pages/ObjectShow"))
const FilterObject = lazy(() => import("./pages/components/FilterObject"))
const Chart = lazy(() => import("./pages/components/ChartStatistics"))
const Assignment = lazy(() => import("./pages/components/Assignment"))
const ObjectTypes = lazy(() => import("pages/ObjectTypes"))
const ObjectTypeCreate = lazy(() => import("pages/CreateObjectTypes"))
const Users = lazy(() => import("pages/Users"))
const UsersEdit = lazy(() => import("pages/UsersEdit"))
const ObjectConstructionType = lazy(() => import("pages/ObjectConstructionType"))
const Permissions = lazy(() => import("pages/Permissions"))
const OnlineReport = lazy(() => import("pages/OnlineReport"))

const publicRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "objects",
        element: <Objects />
      },
      {
        path: "objects/view/:id",
        element: <ObjectsItem />
      },
      {
        path: "news",
        element: <News />
      },
      {
        path: "news/view/:id",
        element: <SelectedNews />
      },
      {
        path: "portal",
        element: <Portal />
      },
      {
        path: "contact",
        element: <Contact />
      },
      {
        path: "statistic",
        element: <Statistics />
      },
    ]
  },
  {
    path: "/login",
    element: <Login />
  },

  //  {
  //       path: "/404",
  //       element: <NotFound/>
  //     },
];

const privateRoutes = {
  "1": [],
  // ADMIN
  "2": [
    {
      path: "/main",
      element: <Dashboard />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <InnerLayout />
        },
        {
          path: "objects",
          element: <InnerLayout />,
          children: [
            {
              index: true,
              element: <FilterObject />,
            }
          ]
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "report/:page_name/:type",
          element: <OnlineReport />,
        },
        {
          path: "assignment/:id",
          element: <Assignment />,
        },
        {
          path: "object/edit/:id",
          element: <EditObject />,
        },
        {
          path: "permissions",
          element: <Permissions />,
        },
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "users/edit/:id",
          element: <UsersEdit />,
        },
        {
          path: "object-type/bind/construction-type/permissions",
          element: <ObjectConstructionType />,
        },
        {
          path: "object-types",
          element: <ObjectTypes />,
        },
        {
          path: "object-types/create",
          element: <ObjectTypeCreate />,
        }
      ]
    }
  ],
  // MODERATOR
  "3": [
    {
      path: "/main",
      element: <Dashboard />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <InnerLayout />
        },
        {
          path: '',
          element: <InnerLayout />,
          children: [
            {
              path: "objects",
              element: <FilterObject />,
            },
            {
              path: "not-confirmed-objects",
              element: <FilterObject />,
            },
          ]
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "show-object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "report/:page_name/:type",
          element: <OnlineReport />,
        },
      ]
    }
  ],
  // USER
  "4": [
    {
      path: "/main",
      element: <Dashboard />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <InnerLayout />
        },
        {
          path: "objects",
          element: <InnerLayout />,
          children: [
            {
              index: true,
              element: <FilterObject />,
            }
          ]
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "report/:page_name/:type",
          element: <OnlineReport />,
        }
      ]
    }
  ],
  "4.2": [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <InnerLayout />
        },
        {
          path: "objects",
          element: <InnerLayout />,
          children: [
            {
              index: true,
              element: <FilterObject />,
            }
          ]
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "report/:page_name/:type",
          element: <OnlineReport />,
        }
      ]
    }
  ],
  "4.1": [
    {
      path: "/main",
      element: <Dashboard />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <InnerLayout />,        
        },
        {
          path: "objects",
          element: <InnerLayout />,
          children: [
            {
              index: true,
              element: <FilterObject />,
            }
          ]
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "report/:page_name/:type",
          element: <OnlineReport />,
        },
        {
          path: "user-show",
          element: <UserShow />,
        },

      ]
    }
  ],
  // EXPERTIZA
  "5": [
    {
      path: "/main",
      element: <Dashboard />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <InnerLayout />,
          children: [
            {
              index: true,
              element: <Chart />,
            },
            {
              path: "objects",
              element: <FilterObject />,
            }
          ]
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "report/:page_name/:type",
          element: <OnlineReport />,
        },
        {
          path: "object/edit/:id",
          element: <EditObject />,
        },
      ]
    }
  ],
  // APZ
  "6": [
    {
      path: "/main",
      element: <Dashboard />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <InnerLayout />,
          children: [
            {
              index: true,
              element: <Chart />,
            },
            {
              path: "objects",
              element: <FilterObject />,
            }
          ]
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "report/:page_name/:type",
          element: <OnlineReport />,
        },
        {
          path: "object/edit/:id",
          element: <EditObject />,
        },
      ]
    }
  ],
  // GASN
  "15": [
    {
      path: "/main",
      element: <Dashboard />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <InnerLayout />,
          children: [
            {
              index: true,
              element: <Chart />,
            },
            {
              path: "objects",
              element: <FilterObject />,
            }
          ]
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "report/:page_name/:type",
          element: <OnlineReport />,
        },
        {
          path: "object/edit/:id",
          element: <EditObject />,
        },
      ]
    }
  ],
  // GASN
  // "15": [
  //   {
  //     path: "/main",
  //     element: <Dashboard/>,
  //   },
  //   {
  //     path: "/dashboard",
  //     element: <DashboardLayout/>,
  //     children: [
  //       {
  //         index: true,
  //         element: <InnerLayout />,
  //         children: [
  //           {
  //             index: true,
  //             element: <Chart />,
  //           },
  //           {
  //             path: "404",
  //             element: <NotFound/>
  //           },
  //           {
  //             path: "objects",
  //             element: <FilterObject />,
  //           }   

  //         ]
  //       },
  //       {
  //         path: "object/:id",
  //         element: <PrivateObjects />,
  //       },
  //       // {
  //       //   path: "object/create",
  //       //   element: <CreateObject />,
  //       // },
  //       {
  //         path: "assignment/:id",
  //         element: <Assignment />,
  //       },
  //       {
  //         path: "object/edit/:id",
  //         element: <EditObject />,
  //       },
  //       {
  //         path: "permissions",
  //         element: <Permissions />,
  //       },
  //       {
  //         path: "users",
  //         element: <Users />,
  //       },
  //       {
  //         path: "users/edit/:id",
  //         element: <UsersEdit />,
  //       },
  //       {
  //         path: "object-type/bind/construction-type/permissions",
  //         element: <ObjectConstructionType />,
  //       },
  //       {
  //         path: "object-types",
  //         element: <ObjectTypes />,
  //       },
  //       {
  //         path: "object-types/create",
  //         element: <ObjectTypeCreate />,
  //       }
  //     ]
  //   }
  // ],

  // AVTO_YO'L
  "16": [
    {
      path: "/main",
      element: <Dashboard />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <InnerLayout />,
          children: [
            {
              index: true,
              element: <Chart />,
            },
            {
              path: "objects",
              element: <FilterObject />,
            },
          ]
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
        {
          path: "report/:page_name/:type",
          element: <OnlineReport />,
        },
        {
          path: "object/edit/:id",
          element: <EditObject2 />,
        },
      ]
    }
  ],
  // PUDRATCHI
  "17": [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <FilterObject />,
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        }
      ]
    }
  ],
  // BUYURTMACHI
  "18": [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <FilterObject />,
        },
        {
          path: "object/:id",
          element: <PrivateObjects />,
        },
      ]
    }
  ],
};

const RoutesComponent = () => {
  const { auth } = useSelector(state => state);

  const handleRoute = (route, key) => {
    if (!get(route, 'children')) {
      const routeProps = {
        element: route?.element,
      }
      if (route?.path) {
        routeProps["path"] = route?.path;
      }
      if (route?.index) {
        routeProps["index"] = route?.index;
      }
      return <Route {...routeProps} key={key} />
    }

    return (
      <Route
        path={route.path}
        element={route.element}
        key={key}
      >
        {
          route?.children?.map((child) => handleRoute(child, key))
        }
      </Route>
    )
  };
  return (
    <Router
    >
      <Suspense fallback={"Loading..."}>
        <Routes
        >
          {
            publicRoutes.map((route, key) => {
              if (route?.children?.length) {
                return <React.Fragment key={key}>
                  <Route
                    path={route.path}
                    element={route.element}
                  >
                    {
                      route?.children?.map((child, i) => {
                        const routeProps = {
                          key: i,
                          element: child?.element,
                        }
                        if (child?.path) {
                          routeProps["path"] = child?.path;
                        }
                        if (child?.index) {
                          routeProps["index"] = child?.index;
                        }

                        return <Route {...routeProps} />
                      })
                    }
                  </Route>
                </React.Fragment>
              } else {
                return <React.Fragment key={key}>
                  <Route
                    path={route.path}
                    element={(() => {
                      return route.element
                    })
                      ()}
                  />
                </React.Fragment>
              }
            })
          }
          {
            auth.isAuthenticated && privateRoutes[get(auth, 'data.user.role[0].id')] ?
              privateRoutes[get(auth, 'data.user.role[0].id') === 4 ?
                get(auth, 'data.user.username') === "m_sharipov" ?
                  4.1
                  : get(auth, 'data.user.id') === 21673 ?
                  4.2
                  : get(auth, 'data.user.role[0].id')
                : get(auth, 'data.user.role[0].id')].map((route, key) => handleRoute(route, key))
              : null
          }
          <Route path={"*"} element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  )
};

export default RoutesComponent;