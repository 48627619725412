import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import get from "lodash.get";
import Actions from '../actions';
import { api } from 'services';

function* LoadRegions() {
  try{
    const { data: { result } } = yield call(api.request.get, api.queryBuilder("/regions", {perPage: 30}));
    yield put(Actions.REGIONS.success(get(result,'data', [])));
  }
  catch(error){
    yield put(Actions.REGIONS.failure({
      error: get(error, 'response.data', '')
    }));
  }
}

function* LoadMarquee() {
  try{
    const { data: { result } } = yield call(api.request.get, api.queryBuilder("/get-begushaya", {}));
    yield put(Actions.MARQUEE.success(get(result,'data', {})));
  }
  catch(error){
    yield put(Actions.MARQUEE.failure({
      error: get(error, 'response.data', '')
    }));
  }
}

function* Notification() {
  try{
    const { data: { result } } = yield call(api.request.get, api.queryBuilder("/moderator/shaffof-object-list", {}));
    yield put(Actions.NOTIFICATION.success(get(result,'meta.total', {})));
  }
  catch(error){
    yield put(Actions.NOTIFICATION.failure({
      error: get(error, 'response.data', '')
    }));
  }
}

export default function* systemSaga() {
  yield all([
    takeLatest(Actions.REGIONS.REQUEST, LoadRegions),
    takeLatest(Actions.MARQUEE.REQUEST, LoadMarquee),
    takeLatest(Actions.NOTIFICATION.REQUEST, Notification),
  ])
}