import { createRoutine } from 'redux-saga-routines';

//System Actions
const LOGIN = createRoutine('LOGIN');
const LOGIN_WITH_SIGNATURE = createRoutine('LOGIN_WITH_SIGNATURE');
const LOGOUT = createRoutine('LOGOUT');
const CHANGE_LANG = createRoutine('LANGUAGE');

const REGIONS = createRoutine("GET_REGIONS");
const MARQUEE = createRoutine("GET_MARQUEE");

const NOTIFICATION  = createRoutine("NOTIFICATION");

const actions = {
  LOGIN,
  LOGIN_WITH_SIGNATURE,
  LOGOUT,
  CHANGE_LANG,
  REGIONS,
  MARQUEE,
  NOTIFICATION
};

export default actions;
