import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import Actions from './actions';
import{ api } from 'services';
import get from 'lodash.get';

function* LoadAll({ payload }) {
  const {
    url='',
    name='',
    params={},
    append=false,
    prepend=false,
    asData=false,
    dataKey="data",
    metaKey="meta",
    cb,
    callback
  } = payload;
  try{
    const { data: { result } } = yield call(api.request.get, api.queryBuilder(url, {...params}));
    let data = asData ? result : get(result, dataKey);
    if(callback){
      data = callback(data);
    }
    yield put(Actions.LoadAll.success({
      name,
      append,
      prepend,
      data,
      meta: get(result, metaKey)
    }));

    yield call(cb.success, data);
  }
  catch(error){
    yield put(Actions.LoadAll.failure({
      name,
      error: get(error, 'response.data', '')
    }));

    yield call(cb.error, get(error, 'response.data', ''))
  }
  finally {
    yield call(cb.finally)
  }
}

function* LoadRating({ payload }) {
  const {
    url,
    name,
    params,
    cb,
  } = payload;
  try{
    const { data  } = yield call(api.request_list.get, api.queryBuilder(url, {...params}));

    yield put(Actions.LoadRating.success({
      name,
      data,
    }));

    yield call(cb.success, data);
  }
  catch(error){
    yield put(Actions.LoadRating.failure({
      name,
      error: get(error, 'response.data', '')
    }));

    yield call(cb.error, get(error, 'response.data', ''))
  }
  finally {
    yield call(cb.finally)
  }
}

function* LoadOne({ payload }) {
  const {
    url='',
    name='',
    params={},
    asData=false,
    dataKey="data",
    cb,
    callback
  } = payload;
  try{
    const { data: { result } } = yield call(api.request.get, api.queryBuilder(url, {...params}));
    let data = asData ? result : get(result, dataKey);
    if(callback){
      data = callback(data);
    }

    yield put(Actions.LoadOne.success({
      name,
      data
    }));
    yield call(cb.success, data);
  }
  catch(error){
    yield put(Actions.LoadOne.failure({
      name,
      error: get(error, 'response.data', '')
    }));
    yield call(cb.error, get(error, 'response.data', ''))
  }
  finally {
    yield call(cb.finally)
  }
}

function* Create({ payload }) {
  const {
    url,
    name,
    params,
    values,
    prepend=false,
    append=false,
    cb
  } = payload;
  try{
    const { data: { result  } } = yield call(api.request.post, api.queryBuilder(url, {...params}), values);
    yield put(Actions.CREATE.success({
      name,
      data: get(result, "data"),
      prepend,
      append
    }));

    yield call(cb.success, get(result, "data"));
  }
  catch(error){
    yield put(Actions.CREATE.failure({
      name,
      error: get(error, 'response.data', '')
    }));

    yield call(cb.error, get(error, 'response.data', ''))
  }
  finally {
    yield call(cb.finally);
  }
}

function* Update({ payload }) {
  const {
    id,
    url,
    name,
    params,
    values,
    cb
  } = payload;
  try{
    const { data: { data  } } = yield call(api.request.put, api.queryBuilder(url, {...params}), values);

    yield put(Actions.UPDATE.success({
      id,
      name,
      data,
    }));

    yield call(cb.success, data);
  }
  catch(error){
    yield put(Actions.UPDATE.failure({
      name,
      error: get(error, 'response.data', '')
    }));

    yield call(cb.error, get(error, 'response.data', ''))
  }
  finally {
    yield call(cb.finally);
  }
}

function* Delete({ payload }) {
  const {
    id,
    url,
    name,
    params,
    cb={
      success: () => {},
      error: () => {},
      finally: () => {}
    }
  } = payload;
  try{
    yield call(api.request.delete, api.queryBuilder(url, {...params}));

    yield put(Actions.DELETE.success({
      id,
      name,
    }));

    yield call(cb.success, id);
  }
  catch(error){
    yield put(Actions.DELETE.failure({
      name,
      error: get(error, 'response.data', '')
    }));

    yield call(cb.error, get(error, 'response.data', ''))
  }
  finally {
    yield call(cb.finally);
  }
}

function* ByMethod({ payload }) {
  const {
    method="post",
    isUpdate=false,
    url,
    name,
    asData=false,
    id,
    params={},
    values={},
    cb
  } = payload;
  try{
    const { data: { result } } = yield call(api.request[method], api.queryBuilder(url, {...params}), values);

    let data = asData ? result : get(result, "data");

    if(isUpdate){
      yield put(Actions.UPDATE.success({
        id,
        name,
        data,
      }));
    }

    yield call(cb.success, data);
  }
  catch(error){
    if(isUpdate){
      yield put(Actions.CREATE.failure({
        name,
        error: get(error, 'response.data', '')
      }));
    }

    yield call(cb.error, get(error, 'response.data', ''))
  }
  finally {
    yield call(cb.finally);
  }
}


export default function* schemaSaga() {
  yield all([
    takeEvery(Actions.LoadAll.REQUEST, LoadAll),
    takeEvery(Actions.LoadRating.REQUEST, LoadRating),
    takeEvery(Actions.LoadOne.REQUEST, LoadOne),
    takeLatest(Actions.DELETE.REQUEST, Delete),
    takeLatest(Actions.CREATE.REQUEST, Create),
    takeLatest(Actions.UPDATE.REQUEST, Update),
    takeEvery(Actions.METHOD.REQUEST, ByMethod),

  ])
}